import React from "react";

const HeadlineComponent = ({ children, alignment = "center", style }) => (
  <div
    style={{
      display: "flex",
      justifyContent: alignment,
      alignItems: "center",
      paddingRight: "20px",
      paddingLeft: "20px"

    }}
  >
    <h1 style={{ fontWeight: 300, ...style }}>{children}</h1>
  </div>
);

export default HeadlineComponent;

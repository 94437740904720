import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./CloseButton.css";

function CloseButton({ eventClose }) {
  return (
    <div
      className={"closeButton"}
      onClick={() => {
        const closeEvent = new Event(eventClose);
        document.dispatchEvent(closeEvent);
      }}
    >
      <FontAwesomeIcon icon={faTimes} />
    </div>
  );
}

export default CloseButton;

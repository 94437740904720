import React from "react";
import StageImagesAddon from "../../components/StageImagesAddon/StageImagesAddon";
import Wave from "../../components/Wave/Wave";
import Headline from "../../components/Headline/Headline";
import StageImages from "../../components/StageImages/StageImages";
import CustomCheckmark from "../../components/Icon/Icon";
import CloseButton from "../../components/CloseButton/CloseButton";
import DevButton from "../Devmode/DevButton";

import { List, ListItem } from "./components/List/List";
import Regular from "./components/Regular/Regular";
import Card from "../../components/Card/Card";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faShoppingCart, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import "./Overview.css";
import Individual from "./components/Individual/Individual";
library.add(faShoppingCart, faEnvelope);

export default function Overview({
  modal,
  devmode,
  resolve,
  setCurrentPage,
  currentType,
  salesforce_call,
  reject,
  setCurrentType,
  setApiEndpoint,
  email,
  firstName,
  lastName,
  name,
}) {
  const { t } = useTranslation("common");
  const [open, setOpen] = React.useState(false);
  return (
    <div className={"wrapper"}>
      {modal && modal.eventClose ? (
        <>
          <CloseButton eventClose={modal.eventClose} />
          {devmode ? (
            <DevButton
              open={open}
              setOpen={setOpen}
              resolve={resolve}
              reject={reject}
              currentType={currentType}
              setCurrentPage={setCurrentPage}
              setCurrentType={setCurrentType}
              setApiEndpoint={setApiEndpoint}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      <div className={"headline"}>
        <Headline alignment="flex-start" style={{ margin: "48px 0 16px 0" }}>
          <strong>{t("overview.headline.summary")}</strong>&nbsp;
          {t("overview.headline.for")}&nbsp;{name}
        </Headline>
      </div>

      <div className={`columns ${currentType}`}>
        <Card title={t("overview.card.headline")} headlineStyle={{ margin: 0 }}>
          <List>
            <ListItem txt={t("overview.card.list.item1")}>
              <CustomCheckmark className={"ops-rep-man-icon"} />
            </ListItem>
            <ListItem txt={t("overview.card.list.item2")}>
              <CustomCheckmark className={"ops-rep-man-icon"} />
            </ListItem>
            <ListItem txt={t("overview.card.list.item3")}>
              <CustomCheckmark className={"ops-rep-man-icon"} />
            </ListItem>
          </List>
        </Card>
        <div style={{ position: "relative" }}>
          <StageImagesAddon style={{ maxWidth: "30%" }}></StageImagesAddon>
          <StageImages type={currentType}></StageImages>
        </div>
      </div>

      <Wave />

      {currentType === "default" ? (
        <Regular salesforce_call={salesforce_call} />
      ) : currentType === "individual" ? (
        <Individual
          salesforce_call={salesforce_call}
          email={email}
          firstName={firstName}
          lastName={lastName}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

import React from "react";
import Paragraph from "../../components/Paragraph/Paragraph";
import "./Error.css";
import StageImagesAddon from "../../components/StageImagesAddon/StageImagesAddon";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import CloseButton from "../../components/CloseButton/CloseButton";
import { useTranslation } from 'react-i18next';

export default function TheError({ modal }) {
  const { t } = useTranslation('common');
  return (
    <div
      className={"ErrorContainer"}
      style={{
        width: "100%",
        height: "95vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "#fff"
      }}
    >
      <div
        className={"ErrorContainerInner"}
        style={{
          maxWidth: "1080px",
          width: "calc(100% - 80px)",
          margin: "0 auto",
          position: "relative"
        }}
      >
        {modal && modal.eventClose ? (
          <CloseButton eventClose={modal.eventClose} />
        ) : (
            <></>
          )}
        <StageImagesAddon
          style={{
            right: "unset",
            left: "-4rem",
            bottom: "-11rem",
            transform: "rotate(180deg)"
          }}
        ></StageImagesAddon>
        <Card title=''>
          <StageImagesAddon
            style={{
              bottom: "unset",
              top: "-3rem",
              right: "-5rem",
              zIndex: 2
            }}
            color='#FFDC0F'
          ></StageImagesAddon>
          <p
            style={{
              maxWidth: "720px",
              margin: "0 auto 2rem auto",
              textAlign: "center",
              fontSize: "28px"
            }}
          >
            {t('errorb.headline')}
          </p>
          <Paragraph
            style={{
              textAlign: "center"
            }}
            txt={t('errorb.message')}
          />
          <br></br>
          <Paragraph
            style={{
              textAlign: "center"
            }}
            setInnerHtml={true}
            txt={t('errorb.signature')}
          />
        </Card>
      </div>
      <Footer />
    </div>
  );
}

import i18n from "i18next";
import i18next from "i18next";

import locales from "./i18n/locales";

const resources = {
    en: {
        common: locales.en
    },
    de: {
        common: locales.de
    },
    es: {
        common: locales.es
    },
    it: {
        common: locales.it
    },
    pt: {
        common: locales.pt
    },
    fr: {
        common: locales.fr
    },
    pl: {
        common: locales.pl
    },
    nl: {
        common: locales.nl
    }
};

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'de',                              // language to use
    resources
});

export default i18n
import React from "react";
import "./StageImagesAddon.css";

const StageImagesAddonComponent = ({ style, color = "#0dbedc" }) => (
  <svg
    width={216.453}
    height={184.316}
    viewBox='0 0 216.453 184.316'
    className={"ImagesAddon"}
    style={style}
  >
    <defs>
      <style>
        {".form-a{opacity:0.197;fill:url(#form-a);}.form-b{fill:#0dbedc;}"}
      </style>
      <pattern
        id='form-a'
        preserveAspectRatio='none'
        width='100%'
        height='100%'
        viewBox='0 0 197 197'
      >
        <image
          width={197}
          height={197}
          xlinkHref={require("./ComponentTMP_5-image.png")}
        />
      </pattern>
    </defs>
    <g transform='translate(-25.214 -9.749)'>
      <path
        className='form-a'
        d='M38.086,100.268C9.824,100.268,0,76.624,0,48.345S36.7,0,64.966,0,119.55,20.065,119.55,48.345,66.347,100.268,38.086,100.268Z'
        transform='translate(110.618 93.798)'
      />
      <path
        className='form-b'
        style={{ fill: color }}
        d='M79.268,154.4c62.665,12.6,125.339-21.413,135.632-71.221,5.187-25.1-2.387-49.019-26.21-67.364s-61.1-20.9-88.224-6.759C91.46,13.744,84.533,21.309,74.574,24.364c-14.22,4.362-35.325.077-51.776,11.463-35.057,24.262-23.962,62.958-4.779,85.02a87.522,87.522,0,0,0,9.658,9.472C41.5,141.967,59.635,150.449,79.268,154.4Z'
        transform='translate(25.214 9.749)'
      />
    </g>
  </svg>
);

export default StageImagesAddonComponent;

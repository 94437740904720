import React from "react"
import Paragraph from "../../components/Paragraph/Paragraph"
import StageImagesAddon from "../../components/StageImagesAddon/StageImagesAddon"
import Button from "../../components/Button/Button"
import Card from "../../components/Card/Card"
import Footer from "../../components/Footer/Footer"
import CloseButton from "../../components/CloseButton/CloseButton"
import { useTranslation } from 'react-i18next';
import "./Error.css"

export default function TheError({ modal, currentPageHandler }) {
  const { t } = useTranslation('common');
  return (
    <div
      className={"ErrorContainer"}
      style={{
        width: "100%",
        height: "95vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "#fff"
      }}
    >
      {modal && modal.eventClose ? (
        <CloseButton eventClose={modal.eventClose} />
      ) : (
          <></>
        )}
      <div
        className={"ErrorContainerInner"}
        style={{
          maxWidth: "1080px",
          width: "calc(100% - 80px)",
          margin: "0 auto 3rem auto",
          position: "relative"
        }}
      >
        <StageImagesAddon
          style={{
            right: "unset",
            left: "-2rem",
            bottom: "-8rem",
            transform: "rotate(180deg)"
          }}
        ></StageImagesAddon>
        <Card title=''>
          <StageImagesAddon
            style={{
              bottom: "unset",
              top: "-3rem",
              right: "-5rem",
              zIndex: 2
            }}
            color='#FFDC0F'
          ></StageImagesAddon>
          <Paragraph
            setInnerHtml={true}
            txt={t('error.headline')}
            style={{
              maxWidth: "720px",
              fontSize: "1.8rem"
            }}
          />
          <Paragraph
            txt={t('error.message')}
          />

          <Button
            title={t('error.back')}
            icon={"arrow-left"}
            onClick={() => {
              currentPageHandler("overview");
            }}
          />
        </Card>
      </div>
      <Footer />
    </div>
  );
}

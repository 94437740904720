import React from "react";
import { useTranslation } from "react-i18next";
/* eslint-disable-next-line */
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./Footer.css";

export default function Footer({ nolink = false, overview, individual, bg }) {
  const { t } = useTranslation("common");
  return (
    <Router>
      <footer
        style={{
          padding: "0 20px",
          zIndex: 1,
          position: "absolute",
          bottom: "0px",
          left: 0,
          right: 0,
          background: bg ? "#fff" : "transparent",
        }}
      >
        <div className={"footerWrapper"}>
          <span style={{ fontSize: "11px" }}>
            {nolink ? (
              t("overview.footer.imprint")
            ) : (
              <Link
                style={{ color: "inherit", textDecoration: "none" }}
                to={{ pathname: t("overview.footer.imprintlink") }}
                target="_blank"
              >
                {t("overview.footer.imprint")}
              </Link>
            )}{" "}
            /{" "}
            <Link
              style={{ color: "inherit", textDecoration: "none" }}
              to={{ pathname: t("overview.footer.privacylink") }}
              target="_blank"
            >
              {t("overview.footer.datapr")}
            </Link>
          </span>
          {overview && (
            <span style={{ fontSize: "11px" }}>
              {t("overview.regular.footer")}
            </span>
          )}
          {individual && (
            <span
              dangerouslySetInnerHTML={{
                __html: t("overview.individual.footer"),
              }}
            />
          )}
        </div>
      </footer>
    </Router>
  );
}

import React from "react";
import "./Button.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Button({ icon, title, onClick, style, color, children }) {
  return (
    <div className={"Button"} onClick={onClick} style={{ backgroundColor: color, border: color, ...style }}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {title && title !== "" ? (
        <span style={{ paddingTop: "0.2rem", paddingLeft: "0.5rem" }}>          {title}
        </span>

      ) : (
          <></>
        )}
    </div>
  );
}

import React from "react";

export default function customCheckmark({ style, className }) {
  return (
    <svg
      style={style}
      className={className}
      width={23.5}
      height={23.5}
      viewBox='0 0 23.5 23.5'
    >
      <defs>
        <style>
          {
            ".icon-check{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:1.5px;}"
          }
        </style>
      </defs>
      <path
        className='icon-check'
        d='M11,22A11,11,0,1,0,0,11'
        transform='translate(22.75 22.75) rotate(180)'
      />
      <path
        transform='translate(7 3)'
        d='M14.288.1a.383.383,0,0,1,.279-.1.519.519,0,0,1,.314.1l.978.989a.321.321,0,0,1,.14.273.419.419,0,0,1-.14.307L5.38,11.9A.383.383,0,0,1,5.1,12a.519.519,0,0,1-.314-.1L.14,7.33A.321.321,0,0,1,0,7.057.419.419,0,0,1,.14,6.75L1.118,5.8a.439.439,0,0,1,.314-.136.335.335,0,0,1,.279.136L5.1,9.1Z'
      />
    </svg>
  );
}

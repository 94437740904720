import React, { useState, useEffect } from "react";
import Overview from "./Overview/Overview";
import Thanks from "./Thanks/Thanks";
import Error from "./Error/Error";
import ErrorB from "./Error/ErrorB";

export default function Main({
  type,
  resolve,
  reject,
  devmode,
  modal,
  lang,
  authToken,
  tsId,
  email,
  firstName,
  lastName,
  name,
  setLoaded,
}) {
  if (type === null) {
    type = "default";
  }
  const [currentPage, setCurrentPage] = useState("overview");
  const [currentType, setCurrentType] = useState(type);

  const [apiEndpoint, setApiEndpoint] = useState(
    "https://api.yeonv.com/ts/isKeyAccount"
  );
  const [apiKeyEndpoint, setApiKeyEndpoint] = useState("");
  const [checked, setChecked] = useState(false);
  /* START: Simulating (Salesforce) API  */

  // async function apiCall_isKeyAccount() {
  //   try {
  //     // Default options are marked with *
  //     let isKeyAccount = await fetch(API_URL, {
  //       method: 'POST', // *GET, POST, PUT, DELETE, etc.
  //       mode: 'cors', // no-cors, *cors, same-origin
  //       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  //       credentials: 'same-origin', // include, *same-origin, omit
  //       headers: {
  //         'Authorization': 'Bearer' + authToken,
  //         'Content-Type': 'application/json'
  //         // 'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //       redirect: 'follow', // manual, *follow, error
  //       referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  //       body: JSON.stringify(tsId) // body data type must match "Content-Type" header
  //     });

  //     console.log(isKeyAccount);
  //     if (isKeyAccount.XXX === "AAA") {
  //       setCurrentType('individual')
  //     }
  //   } catch (err) {
  //     console.log(err); // TypeError: failed to fetch
  //   }
  // }

  async function apiCall_book(newFirstName, newLastName, newEmail) {
    const bodyData =
      currentType === "individual"
        ? {
            tsId: tsId,
            email: newEmail || email,
            technicalName: "REPUTATION_MANAGEMENT",
            language: lang,
            firstName: newFirstName || firstName,
            lastName: newLastName || lastName,
            date: new Date(),
          }
        : {
            tsId: tsId,
            email: email,
            technicalName: "REPUTATION_MANAGEMENT",
            language: lang,
            firstName: firstName,
            lastName: lastName,
            date: new Date(),
          };
    try {
      // Default options are marked with *
      let response = await fetch(apiEndpoint, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "omit", // include, *same-origin, omit
        headers: {
          Authorization: "Bearer " + authToken, // eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJRbW9DeGRmN2JWSllDZ2RhNTRxWHNTZElfYlNVNDdrdzUycjlhYXBWcWNBIn0.eyJqdGkiOiI2YzE2MzNiZi03MWMwLTQ5MmUtYjFmNS1jNDFhN2FkMWFlZDIiLCJleHAiOjE2MDA3NDY5NDUsIm5iZiI6MCwiaWF0IjoxNjAwNzAzNzQ1LCJpc3MiOiJodHRwczovL2xvZ2luLWludGVnci5ldHJ1c3RlZC5jb20vYXV0aC9yZWFsbXMvYnVzaW5lc3MtSU5URUdSIiwiYXVkIjoicmVhbG0tbWFuYWdlbWVudCIsInN1YiI6IjBmMjA0OTMzLTU4YmUtNDdmZS1hZTRiLTFkZDdiZmNkZjBkNyIsInR5cCI6IkJlYXJlciIsImF6cCI6Im1hcnMtY29udHJvbC1jZW50cmUtY2xpZW50IiwiYXV0aF90aW1lIjowLCJzZXNzaW9uX3N0YXRlIjoiODY4OTcwMmYtNDVhOS00Nzc0LTlkODItMTY5M2NkM2M1M2Y3IiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwOi8vMTAuMC4yLjI6NDIwMCIsImh0dHBzOi8vYWRtaW4uZXRydXN0ZWQua29lbG4iLCJodHRwczovL2FwcC5ldHJ1c3RlZC5rb2VsbiIsImh0dHA6Ly9sb2NhbGhvc3Q6NDIwMCIsImh0dHA6Ly9sb2NhbGhvc3Q6NDg0OCJdLCJyZXNvdXJjZV9hY2Nlc3MiOnsicmVhbG0tbWFuYWdlbWVudCI6eyJyb2xlcyI6WyJtYW5hZ2UtdXNlcnMiXX19LCJzY29wZSI6ImVtYWlsIHByb2ZpbGUiLCJhcmVhcy1hbGxvd2VkIjpbIm1hcnMiXSwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiJNYXJ0aWFuIENvb2xuYW1lIiwicHJlZmVycmVkX3VzZXJuYW1lIjoibWFydGlhbl9kZV8yQHRydXN0ZWRzaG9wcy5rb2VsbiIsImh0dHBzOi8vZXRydXN0ZWQuY29tIjp7ImFjY291bnRSZWYiOiJhY2MtOWJlMzUwZDctYmQ4NS00NDY1LWI0ZGEtNjJmZWM1OTM5ZjNjIiwicm9sZSI6Im93bmVyIiwicGVybWlzc2lvbnMiOnsidXJuIjoidXJuOmV0cnVzdGVkOioifX0sImxvY2FsZSI6ImVuIiwiZ2l2ZW5fbmFtZSI6Ik1hcnRpYW4iLCJmYW1pbHlfbmFtZSI6IkNvb2xuYW1lIiwiZW1haWwiOiJtYXJ0aWFuX2RlXzJAdHJ1c3RlZHNob3BzLmtvZWxuIn0.KnlOdrPFDl7fuIXcxHFaKlLFigYHgwolkSg_2pkzgAVG-HLrR6h6-1grMBbh4WNB_w5iaS3Km6sSv-dEKd2m7myLKv4k2ljO8cuHAJ_mBCd-xuLkViB8gQ55_R6r4vluygbHCrRmwu0vN1yGMAFjW9ShIrO6FAMNEbKM-tROu6zPAD51mdEpBUk8F_BTBaIu46v3D9VcD84yuMqIlg5JOlhSY_r8VCJ02elr8B-GDd9NdXmJM9TUJg-Id4REPBTHpL5zHs627kKajpYkU02vDPnhGOcJEFdr5ZLHgoOa0ZoC-kGB7M1chY08_Urv_QzF9UCWtaHDomRsg4vS5r_FNg', // + authToken,
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(bodyData), // body data type must match "Content-Type" header
      });

      if (response.status === 401) {
        setCurrentPage("error");
        resolve({ status: "ERROR", message: "Unauthorized" });
      }
      if (response.status === 202) {
        resolve({
          status: "PENDING",
          isKeyAccount: currentType === "individual",
        });
        setCurrentPage("thanks");
      }

      // if (response.status === 200) {
      //   resolve({ status: "BOOKED" });
      //   setCurrentPage("thanks");
      // }
      // if (response.status === 201) {
      //   resolve({ status: "NOT_BOOKED" });
      //   setCurrentPage("error");
      // }
    } catch (err) {
      console.log(err); // TypeError: failed to fetch
      resolve({
        status: "ERROR",
        message: err,
      });
      setCurrentPage("error");
    }
  }

  const salesforce_call = (newFirstName, newLastName, newEmail) => {
    apiCall_book(newFirstName, newLastName, newEmail);
  };

  /* END: Simulating (Salesforce) API  */

  useEffect(() => {
    // console.log("<=========process.env=========>", process.env)
    switch (process.env.REACT_APP_STAGE) {
      case "prod":
        setApiEndpoint(
          `https://booking-api.etrusted.com/v0/${
            currentType === "individual" ? "key-account" : "regular"
          }-customer/application`
        );
        break;
      case "qa":
        setApiEndpoint(
          `https://booking-api.etrusted.site/v0/${
            currentType === "individual" ? "key-account" : "regular"
          }-customer/application`
        );
        break;
      default:
      case "integr":
        setApiEndpoint(
          `https://booking-api.etrusted.koeln/v0/${
            currentType === "individual" ? "key-account" : "regular"
          }-customer/application`
        );
    }

    const requestOptions = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: "Bearer " + authToken,
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(), // body data type must match "Content-Type" header
    };

    switch (process.env.REACT_APP_STAGE) {
      case "prod":
        setApiKeyEndpoint(
          `https://booking-api.etrusted.com/v0/is-key-account-customer/query?tsId=${tsId}`
        );
        break;
      case "qa":
        setApiKeyEndpoint(
          `https://booking-api.etrusted.site/v0/is-key-account-customer/query?tsId=${tsId}`
        );
        break;
      default:
      case "integr":
        setApiKeyEndpoint(
          `https://booking-api.etrusted.koeln/v0/is-key-account-customer/query?tsId=${tsId}`
        );
    }

    if (!checked && tsId && tsId.length > 0 && apiKeyEndpoint.length > 0) {
      try {
        fetch(apiKeyEndpoint, requestOptions)
          .then((response) => {
            if (response.status === 401) {
              if (process.env.NODE_ENV !== "development") {
                setCurrentPage("error");
              }
              resolve({ status: "ERROR", message: "Unauthorized" });
              setLoaded(true);
              const loadedEvent = new Event(modal.eventAppLoaded);
              document.dispatchEvent(loadedEvent);
              return;
            } else if (response.status === 204) {
              if (process.env.NODE_ENV !== "development") {
                setCurrentPage("error");
              }
              resolve({ status: "ERROR", message: "invalid tsId" });
              setLoaded(true);
              const loadedEvent = new Event(modal.eventAppLoaded);
              document.dispatchEvent(loadedEvent);
              return;
            } else {
              return response.json();
            }
          })
          .then((data) => {
            if (data) {
              // console.log("IsKeyAccount YZ:", data);
              if (data["isKeyAccount"] === "true") {
                // Yes, this comes back as string
                setCurrentType("individual");
              }

              setLoaded(true);
              const loadedEvent = new Event(modal.eventAppLoaded);
              document.dispatchEvent(loadedEvent);
            }
          });
      } catch (error) {
        console.log("ERROR:", error);
        resolve({ status: "ERROR", message: error });
        if (process.env.NODE_ENV !== "development") {
          setCurrentPage("error");
        }
      }
      setChecked(true);
    }
  }, [
    authToken,
    tsId,
    currentType,
    modal.eventAppLoaded,
    setLoaded,
    apiKeyEndpoint,
    setApiKeyEndpoint,
    resolve,
    checked,
  ]);

  return currentPage === "overview" ? (
    <Overview
      modal={modal}
      devmode={devmode}
      resolve={resolve}
      reject={reject}
      setCurrentPage={setCurrentPage}
      currentType={currentType}
      salesforce_call={salesforce_call}
      setCurrentType={setCurrentType}
      setApiEndpoint={setApiEndpoint}
      email={email}
      firstName={firstName}
      lastName={lastName}
      name={name}
    />
  ) : currentPage === "thanks" ? (
    <Thanks modal={modal} currentType={currentType} />
  ) : currentPage === "error2" ? (
    <Error modal={modal} currentPageHandler={setCurrentPage} />
  ) : (
    <ErrorB modal={modal} />
  );
}

import React, { useState, useEffect, Suspense } from "react";
// import ReactDOM from "react-dom";

import Main from "./pages/Main.js";
import { withTranslation, useTranslation } from "react-i18next";
import "./App.css";

function App({
  resolve,
  reject,
  type,
  devmode,
  modal,
  tsId,
  authTokenKey,
  email,
  firstName,
  lastName,
  lang,
  name,
}) {  
  const urlParams = new URLSearchParams(window.location.search);
  const [loaded, setLoaded] = useState(false);
  if (!type) {
    type = urlParams.get("type");
  }
  if (!devmode) {
    devmode = urlParams.get("devmode") === "1";
  }
  // function afterOpenModal() {
  //   // references are now sync'd and can be accessed.
  // }

  // function closeModal() {
  //   resolve("CANCELLED");
  //   ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
  // }
  const { i18n } = useTranslation("common");
  const [token, setNewToken] = useState(localStorage.getItem(authTokenKey));

  useEffect(() => {
    function getNewAuthToken() {
      const newAuthToken = localStorage.getItem(authTokenKey);

      if (newAuthToken) {
        setNewToken(newAuthToken);
        if (process.env.REACT_APP_STAGE !== "prod") {
          console.log("AuthToken updated: ", newAuthToken);
        }
      }
    }

    window.addEventListener("storage", getNewAuthToken);

    return () => {
      window.removeEventListener("storage", getNewAuthToken);
    };
  }, [token, authTokenKey]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);
  return (
    <div id="ops-booking-form">
      <Suspense fallback="loading">
        <div className={`App ${loaded ? "loaded" : ""}`}>
          <header className="App-header">
            <Main
              type={type}
              resolve={resolve}
              reject={reject}
              devmode={devmode}
              modal={modal}
              tsId={tsId}
              authToken={token}
              email={email}
              firstName={firstName}
              lastName={lastName}
              setLoaded={setLoaded}
              name={name}
              lang={lang}
            />
          </header>
        </div>
      </Suspense>
    </div>
  );
}

export default withTranslation()(App);

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Modal from "react-modal";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import i18next from 'i18next';
import getContainerElement from './util/get-container-element';
import isLocatedInShadowDom from './util/is-located-in-shadow-dom';
import './i18n';
import { config, dom } from '@fortawesome/fontawesome-svg-core';
import retargetEvents from './util/react-shadow-dom-retarget-events';

window.renderRevOpsOrderPage = (state) => {
  if (process.env.REACT_APP_STAGE !== "prod") {
    console.log("THE STATE", state);
  }

  const containerElement = getContainerElement(state);
  if (!containerElement) {
    throw new Error(`No container element found by given reference in 'state.containerElement' or tag id ${state.containerId}`);
  }

  if (isLocatedInShadowDom(containerElement)) {
    window.retargetEventsUnsubscribeList = window.retargetEventsUnsubscribeList || [];
    window.retargetEventsUnsubscribeList.push(retargetEvents(containerElement.getRootNode()));
  }

  configureFontAwesome(containerElement);
  Modal.setAppElement(containerElement);

  return bootstrapApp(containerElement, state);
};

window.unmountRevOpsOrderPage = (state) => {
  const element = getContainerElement(state);

  if (window.retargetEventsUnsubscribeList) {
    for (let i = 0; i < window.retargetEventsUnsubscribeList.length; i++){
      window.retargetEventsUnsubscribeList[i]();
    }
    window.retargetEventsUnsubscribeList = [];
  }

  if (!element) {
    return;
  }

  ReactDOM.unmountComponentAtNode(element);
};

serviceWorker.unregister();

/**
 * Configures Fontawesome to not auto-inject styles but let us do it.
 * @param {HTMLElement} containerElement
 */
function configureFontAwesome(containerElement) {
  config.autoAddCss = false;

  const css = dom.css();
  const styleTag = document.createElement('style');
  styleTag.setAttribute('type', 'text/css');
  styleTag.innerHTML = css;

  containerElement.parentElement.appendChild(styleTag);
}

/**
 * Starts the application in the given containerElement
 *
 * @param {HTMLElement} containerElement
 * @param {*} state
 */
function bootstrapApp(containerElement, state) {
  return new Promise((resolve, reject) =>
    ReactDOM.render(
      <React.StrictMode>
        <I18nextProvider i18n={i18next}>
          <App
            type={state.type}
            devmode={state.devmode}
            resolve={resolve}
            reject={reject}

            modal={state.modal}
            tsId={state.tsId}
            email={state.email}
            name={state.shopUrl || state.name}
            firstName={state.firstName}
            lastName={state.lastName}
            lang={state.lang}
            authTokenKey={state.authTokenKey || "com.ts.auth-id_token"}
          />
        </I18nextProvider>
      </React.StrictMode>,
      containerElement));
}

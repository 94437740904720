import React from "react";
import { useTranslation } from "react-i18next";
import "./Form.css";

export default function Form({
  type,
  children,
  onSubmit,
  email,
  firstName,
  lastName,
  setNewEmail,
  setNewFirstName,
  setNewLastName,
}) {
  const { t } = useTranslation("common");

  switch (type) {
    case "variant_1":
      return (
        <form className="Form" onSubmit={onSubmit}>
          <div className="col col1">
            <div className={"top-row"}>
              <div className={"custom-radio-container"}>
                <input type="radio" id="female" name="gender" value="female" />
                <label htmlFor="female">Frau</label>
                <span className="custom-radio"></span>
              </div>
              <div className={"custom-radio-container"}>
                <input type="radio" id="male" name="gender" value="male" />
                <label htmlFor="male">Herr</label>
                <span className="custom-radio"></span>
              </div>
            </div>
            <div className={"content-row"}>
              <div className={"floating-label"}>
                <input type="text" id="name" name="name" required />
                <label htmlFor="name">Name</label>
              </div>
              <div className={"floating-label"}>
                <input type="email" id="email" name="email" required />
                <label htmlFor="email">E-Mail</label>
              </div>
              <div className={"floating-label"}>
                <input type="phone" id="phone" name="phone" />
                <label htmlFor="phone">Telefon (optional)</label>
              </div>
            </div>
          </div>
          <div className="col col2">
            <div className={"top-row"}></div>
            <div className={"content-row"}>
              <div className={"floating-label"}>
                <textarea id="comment" name="comment"></textarea>
                <label htmlFor="comment">Ihr Kommentar (optional)</label>
              </div>
            </div>
          </div>
          <div className="col col3">
            <div className={"top-row"}>Terminanfrage (optional)</div>
            <div className={"content-row"}>
              <div className={"content-inner-row date-time"}>
                <div className={"floating-label"}>
                  <input type="date" id="date" name="date" />
                  <label htmlFor="date">Datum</label>
                </div>
                <div className={"floating-label"}>
                  <input type="time" id="time" name="time" />
                  <label htmlFor="time">Uhrzeit</label>
                </div>
              </div>
              {children}
            </div>
          </div>
        </form>
      );

    default:
      return (
        <form className="Form" onSubmit={onSubmit}>
          <div className="col col1">
            <div className={"content-row"}>
              <div className={"floating-label"}>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  defaultValue={firstName}
                  onChange={(e) => {
                    setNewFirstName(e.currentTarget.value);
                  }}
                  required
                />
                <label htmlFor="firstName">
                  {t("overview.individual.form.firstName")}
                </label>
              </div>
            </div>
          </div>
          <div className="col col2">
            <div className={"content-row"}>
              <div className={"floating-label"}>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  defaultValue={lastName}
                  onChange={(e) => {
                    setNewLastName(e.currentTarget.value);
                  }}
                  required
                />
                <label htmlFor="lastName">
                  {t("overview.individual.form.lastName")}
                </label>
              </div>
            </div>
            <div className={"content-row"}>{children}</div>
          </div>
          <div className="col col3">
            <div className={"floating-label"}>
              <input
                type="email"
                id="email"
                name="email"
                defaultValue={email}
                onChange={(e) => {
                  setNewEmail(e.currentTarget.value);
                }}
                required
              />
              <label htmlFor="email">
                {t("overview.individual.form.email")}
              </label>
            </div>
          </div>
        </form>
      );
  }
}

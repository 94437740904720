import React from 'react';
import './List.css';

export const List = ({ children }) => {
    return (
        <ul style={{ listStyle: "none", padding: 0 }}>
            {children}
        </ul>
    )
}

export const ListItem = ({ children, txt }) => {
    return (
        <li className={"list-item"}>
            {children}{txt}
        </li>
    )
}
import React from "react";
import Modal from "@material-ui/core/Modal";
import "./Devmode.css";
import Button from "../../components/Button/Button";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import {
  faExclamationTriangle,
  faHourglass,
  faCheck,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
// import // faCommentAltSmile,
// // faUsersClass,
// // faCalendarAlt,
// // faEnvelopeOpenText,
// // faToggleOn,
// // faShippingFast,
// // faShoppingBasket,
// // faHandHoldingHeart,
// // faBars,
// // faTimes,
// "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faGoogle } from "@fortawesome/free-brands-svg-icons";
// import { faGlobeEurope } from "@fortawesome/pro-regular-svg-icons";

library.add(faExclamationTriangle, faHourglass, faCheck, faUserSecret);

const DevButton = ({
  open,
  setOpen,
  resolve,
  reject,
  setCurrentPage,
  setCurrentType,
  setApiEndpoint,
  currentType,
}) => {
  const { i18n } = useTranslation("common");
  return (
    <div>
      <button
        className={"devButton"}
        type="button"
        onClick={() => setOpen(true)}
      >
        <FontAwesomeIcon icon={faUserSecret} />
      </button>
      <Modal open={open} disablePortal onClose={() => setOpen(false)}>
        <div className={"dev-mode"}>
          <div className={"dev-mode-container"}>
            <div style={{ display: "flex" }}>
              Response:
              <Button
                title={"PENDING"}
                icon={"hourglass"}
                onClick={() => {
                  resolve({
                    status: "PENDING",
                    isKeyAccount: currentType === "individual",
                  });
                }}
              />
              {/* <Button
                title={"NOT_BOOKED"}
                icon={"exclamation-triangle"}
                onClick={() => {
                  resolve({ status: "NOT_BOOKED" });
                }}
              />
              <Button
                title={"BOOKED"}
                icon={"check"}
                onClick={() => {
                  resolve({ status: "BOOKED" });
                }}
              /> */}
              <Button
                title={"REJECT"}
                icon={"user-secret"}
                onClick={() => {
                  resolve({
                    status: "ERROR",
                    message: "Here is the error response",
                  });
                }}
              />
              {/* <Button
                title={"REJECT2"}
                icon={"user-secret"}
                onClick={() => {
                  reject({
                    status: "ERROR",
                    message: "Here is the error response",
                  });
                }}
              /> */}
            </div>
            <div style={{ display: "flex" }}>
              Views:
              <Button
                title={"default"}
                onClick={() => {
                  setCurrentType("default");
                }}
              />
              <Button
                title={"individual"}
                onClick={() => {
                  setCurrentType("individual");
                }}
              />
              <Button
                title={"Thanks"}
                onClick={() => {
                  setCurrentPage("thanks");
                }}
              />
              <Button
                title={"error1"}
                onClick={() => {
                  setCurrentPage("error");
                }}
              />
              <Button
                title={"error2"}
                onClick={() => {
                  setCurrentPage("error2");
                }}
              />
            </div>
            <div style={{ display: "flex" }}>
              Booking-API-Endpoint:
              <Button
                title={"INTEGR"}
                onClick={() => {
                  setApiEndpoint("https://booking-api.etrusted.koeln");
                }}
              />
              <Button
                title={"QA"}
                onClick={() => {
                  setApiEndpoint("https://booking-api.etrusted.site");
                }}
              />
              <Button
                title={"PROD"}
                onClick={() => {
                  setApiEndpoint("https://booking-api.etrusted.com");
                }}
              />
            </div>
            <div style={{ display: "flex" }}>
              Language:
              <Button
                title={"de"}
                onClick={() => {
                  i18n.changeLanguage("de");
                }}
              />
              <Button
                title={"en"}
                onClick={() => {
                  i18n.changeLanguage("en");
                }}
              />
              <Button
                title={"es"}
                onClick={() => {
                  i18n.changeLanguage("es");
                }}
              />
              <Button
                title={"fr"}
                onClick={() => {
                  i18n.changeLanguage("fr");
                }}
              />
              <Button
                title={"it"}
                onClick={() => {
                  i18n.changeLanguage("it");
                }}
              />
              <Button
                title={"nl"}
                onClick={() => {
                  i18n.changeLanguage("nl");
                }}
              />
              <Button
                title={"pl"}
                onClick={() => {
                  i18n.changeLanguage("pl");
                }}
              />
              <Button
                title={"pt"}
                onClick={() => {
                  i18n.changeLanguage("pt");
                }}
              />
            </div>

            {/* <div style={{ display: "flex" }}>
              ToBeDeleted:
              <Button
                title={"NOT_BOOKED"}
                icon={faShippingFast}
                onClick={() => {
                  resolve({ status: "NOT_BOOKED" });
                }}
              />
              <Button
                title={"NOT_BOOKED"}
                icon={faShoppingBasket}
                onClick={() => {
                  resolve({ status: "NOT_BOOKED" });
                }}
              />
              <Button
                title={"NOT_BOOKED"}
                icon={faHandHoldingHeart}
                onClick={() => {
                  resolve({ status: "NOT_BOOKED" });
                }}
              />
              <Button
                title={"NOT_BOOKED"}
                icon={faGoogle}
                onClick={() => {
                  resolve({ status: "NOT_BOOKED" });
                }}
              />
              <Button
                title={"NOT_BOOKED"}
                icon={faCalendarAlt}
                onClick={() => {
                  resolve({ status: "NOT_BOOKED" });
                }}
              />
              <Button
                title={"NOT_BOOKED"}
                icon={faTimes}
                onClick={() => {
                  resolve({ status: "NOT_BOOKED" });
                }}
              />
              <Button
                title={"NOT_BOOKED"}
                icon={faBars}
                onClick={() => {
                  resolve({ status: "NOT_BOOKED" });
                }}
              />
            </div> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DevButton;

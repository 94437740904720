/**
 * Gets the container element from state. Either by reference (preferred) or by id
 *
 * @param {*} state
 * @returns {HTMLElement}
 */
export default function getContainerElement(state) {
  return state.container
    || document.getElementById(state.containerId)
    || document.querySelector('control-centre-app').shadowRoot.querySelector(`#${state.containerId}`);
}

import React from "react";

export default function Card({
  title,
  children,
  style,
  headlineStyle,
  className
}) {
  return (
    <div
      className={className}
      style={{
        borderRadius: 20,
        boxShadow: "0 4px 15px rgba(0,0,0,0.5)",
        background: "#fff",
        padding: "30px 50px",
        textAlign: "left",
        flexBasis: "50%",
        flexGrow: 0,
        flexShrink: 1,
        position: "relative",
        ...style
      }}
    >
      <h2 style={{ marginTop: 0, fontSize: "2.2rem", ...headlineStyle }}>
        {title}
      </h2>
      {children}
    </div>
  );
}

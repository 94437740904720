import React from "react";
import "./Paragraph.css";

const Paragraph = ({ txt, style, className, setInnerHtml = false }) => {
  return setInnerHtml ? (
    <p
      style={style}
      className={className}
      dangerouslySetInnerHTML={{ __html: txt }}
    />
  ) : (
    <p style={style} className={className}>
      {txt}
    </p>
  );
};

export default Paragraph;

import React from "react";
import Paragraph from "../../../../components/Paragraph/Paragraph";
import Button from "../../../../components/Button/Button";
import Footer from "../../../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import "./Regular.css";

function Regular({ salesforce_call }) {
  const { t } = useTranslation("common");
  return (
    <div className={"content default"}>
      <h2 style={{ position: "relative" }}>
        {t("overview.regular.price")}&nbsp;
        <img
          src={require("../../../../assets/Arrow.png")}
          alt="Arrow"
          className={"ops-rep-man-arrow"}
        />
      </h2>
      <span>{t("overview.regular.monthly")}*</span>
      <Paragraph
        className={"content-paragraph"}
        setInnerHtml
        txt={t("overview.regular.text")}
        style={{ margin: "16px auto 32px auto" }}
      />

      <Button
        title={t("overview.regular.booknow")}
        icon={"shopping-cart"}
        onClick={() => salesforce_call()}
      />
      <Footer overview />
    </div>
  );
}

export default Regular;

import React from "react";
import Paragraph from "../../../../components/Paragraph/Paragraph";
import Footer from "../../../../components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "../../../../components/Form/Form";
import Card from "../../../../components/Card/Card";
import { useTranslation } from "react-i18next";
import "./Individual.css";

function Individual({ salesforce_call, email, firstName, lastName }) {
  const { t } = useTranslation("common");
  // HERE CHANGE DYNAMIC NAME
  const [newFirstName, setNewFirstName] = React.useState(firstName);
  const [newLastName, setNewLastName] = React.useState(lastName);
  const [newEmail, setNewEmail] = React.useState(email);

  return (
    <div className={"content individual"}>
      <Card
        title={""}
        style={{ maxWidth: "1110px", width: "100%", margin: "0 auto" }}
      >
        <h2
          style={{
            marginTop: 0,
            fontSize: "35px",
            margin: 0,
            position: "relative",
          }}
        >
          {t("overview.individual.headline")}

          <img
            src={require("../../../../assets/Arrow.png")}
            alt="Arrow"
            className={"ops-rep-man-arrow"}
          />
        </h2>
        <Paragraph setInnerHtml txt={t("overview.individual.text")} />
        <Form
          setNewLastName={setNewLastName}
          setNewFirstName={setNewFirstName}
          setNewEmail={setNewEmail}
          onSubmit={(e) => {
            e.preventDefault();
            salesforce_call(newFirstName, newLastName, newEmail);
          }}
          email={email}
          firstName={firstName}
          lastName={lastName}
        >
          <button
            type="submit"
            className={"SubmitButton"}
            style={{ marginTop: "2rem" }}
          >
            <FontAwesomeIcon icon={"envelope"} />
            <span style={{ paddingTop: "0.2rem", paddingLeft: "0.5rem" }}>
              {t("overview.individual.form.send")}
            </span>
          </button>
        </Form>
      </Card>

      <Footer individual />
    </div>
  );
}

export default Individual;

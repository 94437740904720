import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import it from "./it.json";
import pl from "./pl.json";
import pt from "./pt.json";
import nl from "./nl.json";

export default { de, en, es, fr, it, pl, nl, pt };

import React from "react";
import Paragraph from "../../components/Paragraph/Paragraph";
import "./Thanks.css";
import Headline from "../../components/Headline/Headline";
import Wave from "../../components/Wave/Wave";
import Card from "../../components/Card/Card";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import CloseButton from "../../components/CloseButton/CloseButton";
import { useTranslation } from "react-i18next";

export default function({ modal, currentType }) {
  const { t } = useTranslation("common");
  return (
    <div className={"wrapper"}>
      {modal && modal.eventClose ? (
        <CloseButton eventClose={modal.eventClose} />
      ) : (
        <></>
      )}

      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className={"headline"}>
          <Headline style={{ margin: "3rem 0 1rem 0" }}>
            <b>
              {currentType === "individual"
                ? t("thanks.individual.headline")
                : t("thanks.regular.headline")}
            </b>
          </Headline>
        </div>
        <div className={"single-column"}>
          <Card title="">
            <Paragraph
              setInnerHtml={true}
              style={{ textAlign: "center" }}
              txt={
                currentType === "individual"
                  ? t("thanks.individual.message")
                  : t("thanks.regular.message")
              }
            />

            <Button
              title={
                currentType === "individual"
                  ? t("thanks.individual.toreputationmanager")
                  : t("thanks.regular.toreputationmanager")
              }
              icon={"lock-open"}
              onClick={() => {
                const event = new Event(modal.eventClose);
                document.dispatchEvent(event);
              }}
            />
          </Card>
        </div>
        <Wave />
        <div
          style={{
            width: "100%",
            background: "#fff",
            marginTop: "-3rem",
            textAlign: "center",
            flexGrow: 1,
          }}
        >
          <img
            style={{ maxWidth: "90%", marginTop: "-14vw" }}
            src={
              "https://business.trustedshops.de/hubfs/1-TS_B2B/OPS/DEV/RM/Laptop.svg"
            }
            alt="Laptop"
          />
        </div>
        <Footer bg />
      </div>
    </div>
  );
}
